<template>
  <div class="bonus-pages-preloader">
    <div class="bonus-pages-preloader__top">
      <div class="bonus-pages-preloader__left">
        <div class="bonus-pages-preloader__title"/>
        <div class="bonus-pages-preloader__subtitle"/>
        <div class="bonus-pages-preloader__btn"/>
      </div>
      <div class="bonus-pages-preloader__img"/>
    </div>
    <div class="bonus-pages-preloader__content">
      <div class="bonus-pages-preloader__label"/>
      <div
        v-for="n in INITIAL_LISTS_COUNT"
        :key="n"
        class="bonus-pages-preloader__list"
      />
    </div>
  </div>
</template>

  <script setup lang='ts'>

  const INITIAL_LISTS_COUNT = 6;

  </script>
  
  <style src="~/assets/styles/components/skeleton-preloader/bonus-pages.scss" lang="scss" />
  
  